import React from "react";
import { IonIcon } from "@ionic/react";
import { call, mail, location, logoFacebook, logoLinkedin, logoInstagram } from "ionicons/icons";

const ContactPage = () => {

  return (
    <div className="flex flex-col md:flex-row md:justify-center md:items-center antialiased">
      <div className="md:m-10 p-10 md:w-[75vw] bg-[rgba(0,0,0,0.5)] space-y-5 md:rounded-lg md:my-20">
        <div className="block space-y-4 md:grid gap-4 md:grid-cols-[1fr,1fr]">
          <div className="space-y-4">
            <h1 className="font-bold text-white md:text-5xl tracking-wide text-3xl">
              Contact Us
            </h1>
            <p className="pt-2 text-white md:text-md text-sm">
              We are a student-run business at Northwestern University. If you have any questions, comments, or concerns, please reach out to us!
            </p>
          </div>
          <div className="space-y-5">
            <div className="flex flex-col space-y-6 text-white text-lg md:text-xl">
              <div className="inline-flex space-x-2 items-center">
                <IonIcon icon={mail}></IonIcon>
                <a href="mailto:info@splitrideshare.com" target="_blank" rel="noreferrer"> info@splitrideshare.com </a>
              </div>
              <div className="inline-flex space-x-2 items-center">
                <IonIcon icon={location}></IonIcon>
                <span> Northwestern University, Evanston IL</span>
              </div>
            </div>
            <div className="flex space-x-4 text-white text-lg md:text-2xl">
              <a href="https://www.instagram.com/northwesternsplit/?hl=en" target="_blank" rel="noreferrer">
                <IonIcon icon={logoInstagram}></IonIcon>
              </a>
              <a href="https://www.facebook.com/profile.php?id=100093943644868" target="_blank" rel="noreferrer">
                <IonIcon icon={logoFacebook}></IonIcon>
              </a>
              <a href="https://www.linkedin.com/company/splitrideshare/" target="_blank" rel="noreferrer">
                <IonIcon icon={logoLinkedin}></IonIcon>
              </a>
            </div>
          </div>
        </div>
        <form action="https://formsubmit.co/info@splitrideshare.com" method="POST">
          <input type="text" className="hidden" name="_honey" />
          <input type="hidden" name="_captcha" value="false"/>
          <input type="hidden" name="_next" value="http://splitrideshare.com/messagethankyou"/>
          <div className="block md:grid gap-4 md:grid-cols-2">
            <div className="mb-4">
              <label htmlFor="" className="block mb-2 text-white"><span className="text-bold text-lg text-red-600">*</span> Your Name</label>
              <input
                type="text"
                name="name"
                placeholder="eg. Joe Smith"
                required
                className="w-full px-4 py-2 border border-gray-300 rounded focus:outline-none focus:border-purple-500"
              />
            </div>
            <div className="mb-4">
              <label htmlFor="" className="block mb-2 text-white">Phone Number</label>
              <input
                type="tel"
                name="phone"
                placeholder="eg. XXX-XXX-XXXX"
                className="w-full px-4 py-2 border border-gray-300 rounded focus:outline-none focus:border-purple-500"
              />
            </div>
          </div>
          <div className="block md:grid gap-4 md:grid-cols-2">
            <div className="mb-4">
              <label htmlFor="email" className="block mb-2 text-white"><span className="text-bold text-lg text-red-600">*</span> Email Address</label>
              <input
                type="email"
                name="email"
                placeholder="eg. JoeSmith@hotmail.com"
                required
                className="w-full px-4 py-2 border border-gray-300 rounded focus:outline-none focus:border-purple-500"
              />
            </div>
            <div className="mb-4">
              <label htmlFor="school" className="block mb-2 text-white">School/University</label>
              <input
                type="text"
                name="school"
                placeholder="eg. Northwestern, Univ. of Chicago, etc."
                className="w-full px-4 py-2 border border-gray-300 rounded focus:outline-none focus:border-purple-500"
              />
            </div>
          </div>
          <div className="mb-4">
            <label htmlFor="message" className="block mb-2  text-white"><span className="text-bold text-lg text-red-600">*</span> Message</label>
            <textarea
              name="message"
              placeholder="Your Message Here"
              required
              className="w-full px-4 py-2 border border-gray-300 rounded focus:outline-none focus:border-purple-500 resize-none"
              rows="4"
            />
          </div>
          <p className="pt-2 text-white font-thin text-md">
            <span className="text-bold text-red-600">*</span> indicates a required field
          </p>
          <div className="flex justify-end">
            <button type="submit" className="bg-purple-700 text-white px-4 py-2 mt-4 rounded hover:bg-purple-800 transition duration-300">Send Message</button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ContactPage;
