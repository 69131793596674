import React, { useState } from "react";
import { Link } from "react-router-dom";
import { firestore } from "../firebase2";
import { collection, addDoc } from "firebase/firestore";
import { IonIcon } from "@ionic/react";
import { logoFacebook, logoLinkedin, logoInstagram } from "ionicons/icons";

const Footer = () => {
  const [inputValue, setInputValue] = useState("");
  const [submitted, setSubmitted] = useState(false);

  const handleClick = () => {
    console.log("here");
    window.scrollTo(0, 0);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Perform any logic with the input value
    console.log("Submitted value:", inputValue);

    const docData = {
      email: inputValue,
    };

    await addDoc(collection(firestore, "EmailList"), docData);

    // Clear the input value
    setInputValue("");

    // Set the submission status to true
    setSubmitted(true);
  };

    return (
      <div className="flex flex-col justify-center items-center bottom-0 left-0 right-0 w-full bg-gray-800 shadow h-fit">
        <div className="p-[25px] w-full h-fit md:grid gap-4 md:grid-cols-[2fr,1fr,1fr] md:space-y-0 space-y-3">
          <div className="flex flex-col max-w-md mx-auto p-6 bg-gray-100 rounded-lg justify-between">
            {!submitted ? (
              <div>
              <div>
              <h2 className="text-2xl font-bold mb-4">Don't miss a ride again!</h2>
              <p className="text-lg mb-6">Sign up with your email address for regular updates</p>
              </div>
              <div className="flex mb-6 max-w-[80%]">
                <input
                  className="flex-grow px-4 py-2 border border-gray-300 rounded-l-md focus:outline-none focus:border-gray-500"
                  type="email"
                  placeholder="Enter your email"
                  onChange={(e) => setInputValue(e.target.value)}
                />
                <button onClick={handleSubmit} className="px-4 py-2 bg-gray-800 text-white font-semibold rounded-r-md">
                  Submit
                </button>
              </div></div>
            ) : (
              <div className="flex mb-6 font-bold text-xl">
                Vroom Vroom! Check your email for regular Split updates!
              </div>
            )}
            <p className="text-sm text-gray-500">We promise to not use your email for spam!</p>
          </div>
          <div className="container mx-auto px-4">
            <div className="flex flex-wrap items-center justify-start">
              <ul className="flex flex-col items-start space-y-2">
                <h2 className='text-white py-3 font-bold text-2xl'>
                  Information
                </h2>
                {/* <li>
                  <Link className='text-gray-300 text-lg hover:text-white'
                    to="/about" onClick={handleClick}>
                    About
                  </Link>
                </li> */}
                <li>
                  <Link className='text-gray-300 text-lg hover:text-white'
                      to="/" onClick={handleClick}>
                      Book a trip
                  </Link>
                </li>
                <li>
                  <Link className='text-gray-300 text-lg hover:text-white'
                      to="/contact" onClick={handleClick}>
                      Contact us
                  </Link>
                </li>
                <li>
                  <Link className='text-gray-300 text-lg hover:text-white'
                      to="/faq" onClick={handleClick}>
                      FAQs
                  </Link>
                </li>
                <li>
                  <Link className='text-gray-300 text-lg hover:text-white'
                      to="/terms" onClick={handleClick}>
                      Terms and Conditions
                  </Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="container mx-auto px-4">
            <div className="flex flex-wrap items-center justify-start">
              <ul className="flex flex-col items-start space-y-3">
                <h2 className='text-white py-2 font-bold text-2xl'>
                  Connect with us
                </h2>
                <li className='text-gray-300 text-lg hover:text-white'>
                  <a href="https://www.instagram.com/northwesternsplit/?hl=en" target="_blank" rel="noreferrer">
                  <IonIcon icon={logoInstagram}></IonIcon> Instagram
                  </a>
                </li>
                <li className='text-gray-300 text-lg hover:text-white'>
                  <a href="https://www.facebook.com/profile.php?id=100093943644868" target="_blank" rel="noreferrer">
                  <IonIcon icon={logoFacebook}></IonIcon> Facebook
                  </a>
                </li>
                <li className='text-gray-300 text-lg hover:text-white'>
                  <a href="https://www.linkedin.com/company/splitrideshare" target="_blank" rel="noreferrer">
                  <IonIcon icon={logoLinkedin}></IonIcon> LinkedIn
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className='text-sm text-gray-300'>
          &copy; 2024 Splitrideshare LLC
        </div>
      </div>
  );
};

export default Footer;
