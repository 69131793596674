import React, { useState, useEffect } from "react";
import Select from "react-select";
import { Typewriter } from "react-simple-typewriter";
import "react-datepicker/dist/react-datepicker.css";
import { collegeOptions } from "../assets/colleges.js";
import { useNavigate } from "react-router-dom";
import chart from "../assets/chart2.png";
import upchart from "../assets/upchart.svg";
import TripOptions from "../components/trip-options.js";
import { collegeMap } from "../assets/colleges.js";
import CustomDropdown from "../components/CustomDropdown.js";
import Testimonials from "./Testimonials.js";

export default function LogoPage() {

  // document.addEventListener("DOMContentLoaded", function () {
  //   var collegeInput = document.getElementById("college-input");
  //   if (collegeInput) {
  //     collegeInput.addEventListener("focus", function () {
  //       this.setAttribute("autocomplete", "off");
  //     });
  //   }
  // });

  return (
    <div className="mb-[100px]">
      <div className="flex flex-col justify-center items-center text-white">
        <h1 className="text-[8vw] md:text-[4.5vw] font-semibold text-center p-6">
          SPLIT a Ride, Save $$
          <p className="hidden lg:block text-2xl font-medium ">
            We book the Uber for you. Fill out the form and the rest is in our hands.
          </p>
          <p className="lg:hidden text-lg font-medium ">
            We book the Uber for you.
          </p>
          <p className="lg:hidden text-lg font-medium ">
            Fill out the form and the rest is in our hands.
          </p>
        </h1>
        <div className="flex justify-center w-[90vw] lg:w-[60vw] m-10 mb-[25px] p-2 rounded-full bg-white border border-white">
          <CustomDropdown />
        </div>
        <div className="flex justify-center w-[90vw] lg:w-[80vw] mb-[50px]">
          <p className="italic text-sm text-center font-medium">No need to meet up at the same spot with your matches anymore!
           Uber will now pick up each person at their chosen pickup spot!</p>
        </div>
        <div className="hidden lg:flex justify-between items-center h-0 w-[65vw] border border-white"></div>
        <div className="hidden lg:flex justify-between items-center h-0 w-[67vw] mb-8">
          <div className="mb-[15px] text-6xl font-bold">⋅</div>
          <div className="mb-[15px] text-6xl font-bold">⋅</div>
          <div className="mb-[15px] text-6xl font-bold ">⋅</div>
          <div className="mb-[15px] text-6xl font-bold ">⋅</div>
          <div className="mb-[15px] text-6xl font-bold ">⋅</div>
        </div>
        <div className="hidden lg:grid grid-cols-5 gap-8 w-[80vw] h-[40vh]">
          <div className="flex flex-col justify-start bg-white rounded-3xl p-5">
            <div className="text-purple-500 text-4xl"><i class="fas fa-clipboard-list"></i></div>
            <div className="mt-8">
              <div className="text-purple-600 text-[13px] font-bold">Enter Ride Details</div>
              <div className="text-black font-medium text-xs">Input your departure time, flight info, and desired pickup location.</div>
            </div>
          </div>
          <div className="flex flex-col justify-start bg-white rounded-3xl p-5">
            <div className="text-purple-500 text-4xl"><i class="fa-regular fa-bell"></i></div>
            <div className="mt-8">
              <div className="text-purple-600 text-[13px] font-bold">Uber Match Notification</div>
              <div className="text-black font-medium text-xs">We'll match you with 1-2 other students and notify you via text 48 hours before your ride.</div>
            </div>
          </div>
          <div className="flex flex-col justify-start bg-white rounded-3xl p-5">
            <div className="text-purple-500 text-4xl"><i class="fa-regular fa-credit-card"></i></div>
            <div className="mt-8">
              <div className="text-purple-600 text-[13px] font-bold">Payment Info</div>
              <div className="text-black font-medium text-xs">Provide payment details; you'll only be charged after the Uber ride.</div>
            </div>
          </div>
          <div className="flex flex-col justify-start bg-white rounded-3xl p-5">
            <div className="text-purple-500 text-4xl"><i class="fa-regular fa-circle-check"></i></div>
            <div className="mt-8">
              <div className="text-purple-600 text-[13px] font-bold">Text with Details and Confirmation</div>
              <div className="text-black font-medium text-xs">Receive a confirmation text 24 hours in advance with your Uber details.</div>
            </div>
          </div>
          <div className="flex flex-col justify-start bg-white rounded-3xl p-5">
            <div className="text-purple-500 text-4xl"><i class="fa-solid fa-location-dot"></i></div>
            <div className="mt-8">
              <div className="text-purple-600 text-[13px] font-bold">Meet & Ride</div>
              <div className="text-black font-medium text-xs">Go to designated pickup location to meetup with your group and driver!</div>
            </div>
          </div>
        </div>

        {/* Mobile Formatting */}
        <div>
          <div className="lg:hidden flex flex-col gap-y-5 w-[250px]">
            <div className="flex flex-col justify-start bg-white rounded-3xl p-5 w-[150px] h-[225px]">
              <div className="flex justify-between text-purple-500 text-4xl"><i class="fas fa-clipboard-list"></i><i class="fa-solid fa-1"></i></div>
              <div className="mt-4">
                <div className="text-purple-600 text-[13px] font-bold">Enter Ride Details</div>
                <div className="text-black font-medium text-xs">Input your departure time, flight info, and desired pickup location.</div>
              </div>
            </div>
            <div className="flex flex-col justify-start bg-white rounded-3xl p-5 w-[150px] h-[225px] self-end">
              <div className="flex justify-between text-purple-500 text-4xl"><i class="fa-regular fa-bell"></i><i class="fa-solid fa-2"></i></div>
              <div className="mt-4">
                <div className="text-purple-600 text-[13px] font-bold">Uber Match Notification</div>
                <div className="text-black font-medium text-xs">We'll match you with 1-2 other students and notify you via text 48 hours before your ride.</div>
              </div>
            </div>
            <div className="flex flex-col justify-start bg-white rounded-3xl p-5 w-[150px] h-[225px]">
              <div className="flex justify-between text-purple-500 text-4xl"><i class="fa-regular fa-credit-card"></i><i class="fa-solid fa-3"></i></div>
              <div className="mt-4">
                <div className="text-purple-600 text-[13px] font-bold">Payment Info</div>
                <div className="text-black font-medium text-xs">Provide payment details; you'll only be charged after the Uber ride.</div>
              </div>
            </div>
            <div className="flex flex-col justify-start bg-white rounded-3xl p-5 w-[150px] h-[225px] self-end">
              <div className="flex justify-between text-purple-500 text-4xl"><i class="fa-regular fa-circle-check"></i><i class="fa-solid fa-4"></i></div>
              <div className="mt-4">
                <div className="text-purple-600 text-[13px] font-bold">Text with Details and Confirmation</div>
                <div className="text-black font-medium text-xs">Receive a confirmation text 24 hours in advance with your Uber details.</div>
              </div>
            </div>
            <div className="flex flex-col justify-start bg-white rounded-3xl p-5 w-[150px] h-[225px]">
              <div className="flex justify-between text-purple-500 text-4xl"><i class="fa-solid fa-location-dot"></i><i class="fa-solid fa-5"></i></div>
              <div className="mt-4">
                <div className="text-purple-600 text-[13px] font-bold">Meet & Ride</div>
                <div className="text-black font-medium text-xs">Go to designated pickup location to meetup with your group and driver!</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <Testimonials /> */}
    </div>
  );
}
