import React from "react";

const MessageThankYouPage = () => {

  return (
    <div className="flex flex-col justify-center items-center antialiased">
      <div className="flex w-[98vw] min-h-[87vh] justify-center items-center text-white">
        <div className="flex flex-col
         md:flex-row md:space-x-6 md:space-y-0 sm:p-12
         space-y-6 bg-purple-400 w-full max-w-4xl p-8 rounded-xl shadow-lg">
            <div>
              <h1 className="font-bold text-white text-4xl tracking-wide">
                Thank you for your message! We will be in touch soon!
              </h1>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MessageThankYouPage;
