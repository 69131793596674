import React from "react";
import teamphoto from "../assets/teamphoto.png";

const AboutPage = () => {

    return (
        <div className="flex flex-col justify-start items-start md:flew-row md:justify-center md:items-center">
            <div className="flex flex-col bg-[rgba(0,0,0,0.5)] bg-opacity-[85%] w-[100vw] md:w-[75vw] p-10 text-white space-y-5 rounded-lg md:my-20">
                <h1 className="text-5xl font-bold">About Us</h1>
                <img className="w-[95%] h-[50%]"src={teamphoto} alt="Team"></img>
                <h2 className="text-2xl font-bold">
                    Saving students thousands of dollars per year on rides to and from local airports
                </h2>
                <p>
                    Welcome to SPLIT, a rideshare service that allows users to share rides with others. Our mission is to provide a convenient and affordable transportation solution while reducing traffic congestion and promoting sustainability.
                </p>
                <p>
                    At SPLIT, we believe in the power of community and collaboration. By connecting riders heading in the same direction, we optimize routes and make rideshare more efficient. With SPLIT, you can share the cost of your ride, reduce your carbon footprint, and meet interesting people along the way.
                </p>
                <p>
                    Our platform is designed to prioritize safety and reliability. All drivers undergo thorough background checks and our rating system ensures a positive experience for both riders and drivers. We are committed to providing a seamless and enjoyable ride-sharing experience for everyone.
                </p>
                <p>
                    Join SPLIT today and be a part of the future of transportation. Together, we can make rideshare more sustainable, affordable, and social.
                </p>
            </div>
        </div>
    )};

export default AboutPage;