import { initializeApp } from "firebase/app";
import { getFirestore } from "@firebase/firestore";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey:"AIzaSyAuMqDIJgjqpEbCiiXUtTy6UM5mxYeH3nQ",
  authDomain:"split-eb201.firebaseapp.com",
  projectId:"split-eb201",
  storageBucket:"split-eb201.appspot.com",
  messagingSenderId:"509197432207",
  appId:"1:509197432207:web:1e8978a62f14ac4fd547fa",
  measurementId:"G-85J62L5K14"
};

const app = initializeApp(firebaseConfig);
export const firestore = getFirestore(app);
// export const auth = getAuth(app);
// export default app;