import React, { useState } from "react";
import { faqData } from "../assets/faqData";

const FAQPage = () => {
  const [faqActive, setFaqActive] = useState(-1);

  const toggleFaq = (index) => {
    if (faqActive === index) {
      setFaqActive(-1);
      return;
    }
    setFaqActive(index);
  };

  // const faqData = [
  //   {
  //     question: "What is SPLIT?",
  //     answer:
  //       "SPLIT matches students at the same university traveling to the same airport at the same time. We call rides to centralized pickup locations so you can SPLIT an Uber with fellow students and save 40-65%.",
  //   },
  //   {
  //     question: "How can I use SPLIT?",
  //     answer:
  //       "Simply fill out our form with your college, pickup time, and preferred pickup location and we do the rest! We will update you via text messages up until your ride time. Then go to your assigned pickup location at your ride time and go to the airport!",
  //   },
  //   {
  //     question: "After I fill out the form, where will I find my ride information and payment link?",
  //     answer:
  //       "All this information will be texted to you after you fill out the form and before your ride time.",
  //   },
  //   {
  //     question: "How much money will I save?",
  //     answer:
  //       "You will save between 40-65% of your usual ride cost depending on how large of a group you are matched with.",
  //   },
  //   {
  //     question: "How is the price calculated?",
  //     answer: "We are fully transparent about our pricing structure. The price you will pay for a SPLIT ride is: price of uber / size of your group * 6.42% SPLIT fee.",
  //   },
  //   {
  //     question: "Who will I be riding with?",
  //     answer: "You will only be riding with people affiliated with your university. Our system uses email verification to ensure your matches have a valid email address associated with your university. ",
  //   },
  //   {
  //     question: "How can I contact SPLIT?",
  //     answer: "Send us a message at our contact us page or email info@splitrideshare.com.",
  //   },
  //   {
  //     question: "What if my flight gets canceled?",
  //     answer: "If you do not take your ride because your flight is canceled or delayed for more than 3 hours, please send proof of cancellation/delay (boarding pass, voucher, any other travel documents) to info@splitrideshare.com, and we will refund your ride.",
  //   }
  // ];

  return (
    <div className="w-[100vw] flex flex-col md:flex-row md:justify-center md:items-center antialiased md:w-[75%]">
      <div className="container min-h-[100vh] md:min-h-none md:max-w-[1140px] md:m-12 p-8 flex flex-col items-center justify-center bg-[rgba(0,0,0,0.5)] md:rounded-xl md:shadow-xl">
        <span className="hidden md:block text-4xl font-bold text-white">
          Frequently Asked Questions
        </span>
        <span className="md:hidden block text-7xl font-bold text-white">
          FAQs
        </span>
        <div className="grid grid-cols-1 gap-4 w-full mt-8">
          {faqData.map((faq, index) => (
            <div
              key={index}
              onClick={() => toggleFaq(index)}
              className="bg-white mb-8 p-4 rounded-lg cursor-pointer"
            >
              <div className="flex justify-between items-center">
                <h3 className={faqActive === index ? "font-bold" : "text-secondary"}>
                  {faq.question}
                </h3>
                <span className="text-primary">
                  {faqActive === index ? "-" : "+"}
                </span>
              </div>
              <p className={faqActive === index ? "block" : "hidden"}>
                {faq.answer}
              </p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default FAQPage;
