export const collegeMap = {
    // "Boston College": {
    //     "domain": "bc.edu",
    //     "example-email": "bsmith",
    //     "location-options": ["BOS"],
    //     "pickup-spots": ["Cheverus Hall"],
    //     "default-spot": "Cheverus Hall",
    //     "color": "#8a100b",
    //     "gradient": "linear-gradient(to right, #8a100b, #b29d6c)",
    // },
    // "Boston University": {
    //     "domain": "bu.edu",
    //     "example-email": "bsmith",
    //     "location-options": ["BOS"],
    //     "pickup-spots": ["Agganis Arena", "Marsh Plaza"],
    //     "default-spot": "Marsh Plaza",
    //     "color": "#CC0000",
    //     "gradient": "linear-gradient(to right, #cc0000, #ffffff)",
    // },
    "Case Western Reserve University": {
        "domain": "case.edu",
        "example-email": "bob.smith",
        "location-options": ["CLE"],
        "pickup-spots": [
            {"name": "The Ugly Statue", "coordinates": {"lat": 41.512387821394086, "lng": -81.60570392223146}},
            {"name": "The Parking Lot Opposite Fribley", "coordinates": {"lat": 41.501202826786084, "lng": -81.60331525249146}},
            {"name": "The Lake (by the Cleveland art museum)", "coordinates": {"lat": 41.507205350700076, "lng": -81.61011862766067}},
        ],
        "timezone": "America/New_York",
        "timezone-short": "EST",
        "default-spot": "The Ugly Statue",
        "color": "#4e2a84",
        "gradient": "linear-gradient(45deg, #e1bee7, #ce93d8, #ab47bc, #8e24aa, #6a1b9a, #4a148c, #6a1b9a, #8e24aa, #ab47bc, #ce93d8, #e1bee7)"
    },
    // "DePaul University": {
    //     "domain": "depaul.edu",
    //     "example-email": "bsmith",
    //     "location-options": ["ORD", "MDW"],
    //     "pickup-spots": ["The Quad"],
    //     "default-spot": "The Quad",
    //     "color": "#af272f",
    //     "gradient": "linear-gradient(45deg, #b3e5fc, #87b9f3, #5a9aec, #2e81e3, #0066da, #0057b7, #0066da, #2e81e3, #5a9aec, #87b9f3, #b3e5fc)"
    // },
    "Duke University": {
        "domain": "duke.edu",
        "example-email": "du111",
        "location-options": ["RDU"],
        "pickup-spots": [
            {"name": "Richard White Lecture Hall", "coordinates": {"lat": 36.00528397269563, "lng": -78.91396589746107}},
            {"name": "102 Wannamaker Dorm Ln", "coordinates": {"lat": 35.99876905466886, "lng": -78.93907171553838}},
            {"name": "104 Union Drive", "coordinates": {"lat": 36.00014122526611, "lng": -78.93991214280929}},
        ],
        "timezone": "America/New_York",
        "timezone-short": "EST",
        "default-spot": "Richard White Lecture Hall",
        "color": "#4e2a84",
        "gradient": "linear-gradient(45deg, #e1bee7, #ce93d8, #ab47bc, #8e24aa, #6a1b9a, #4a148c, #6a1b9a, #8e24aa, #ab47bc, #ce93d8, #e1bee7)"
    },
    // "Harvard University": {
    //     "domain": "harvard.edu",
    //     "example-email": "bobsmith",
    //     "location-options": ["BOS"],
    //     "pickup-spots": ["The Gates"],
    //     "default-spot": "The Gates",
    //     "color": "#a51c30",
    //     "gradient": "linear-gradient(to right, #a51c30, #1e1e1e)",
    // },
    // "Illinois Institute of Technology": {
    //     "domain": "iit.edu",
    //     "example-email": "bobsmith",
    //     "location-options": ["ORD", "MDW"],
    //     "pickup-spots": ["Rowe Village"],
    //     "default-spot": "Rowe Village",
    //     "color": "#d12030",
    //     "gradient": "linear-gradient(to right, #d12030, #040707)",
    // },
    // "Northeastern University": {
    //     "domain": "northeastern.edu",
    //     "example-email": "smith.bob",
    //     "location-options": ["BOS"],
    //     "pickup-spots": ["West Village H"],
    //     "default-spot": "West Village H",
    //     "color": "#c8102e",
    //     "gradient": "linear-gradient(to right, #c8102e, #000000)",
    // },
    // "Northern Illinois University": {
    //     "domain": "niu.edu",
    //     "example-email": "bobsmith",
    //     "location-options": ["ORD", "MDW"],
    //     "pickup-spots": ["Stevenson Towers"],
    //     "default-spot": "Stevenson Towers",
    //     "color": "#c8102e",
    //     "gradient": "linear-gradient(to right, #c8102e, #000000)",
    // },
    "Northwestern University": {
        "domain": "northwestern.edu",
        "example-email": "bobsmith2027",
        "location-options": ["ORD", "MDW"],
        "pickup-spots": [
            {"name": "Sargent Hall", "coordinates": {"lat": 42.058750, "lng": -87.676167}},
            {"name": "South Campus Loop", "coordinates": {"lat": 42.051194, "lng": -87.678380}},
            {"name": "Garrett Loop", "coordinates": {"lat": 42.056189, "lng": -87.675889}}
        ],
        "timezone": "America/Chicago",
        "timezone-short": "CST",
        "default-spot": "Garrett Loop",
        "color": "#4e2a84",
        "gradient": "linear-gradient(45deg, #e1bee7, #ce93d8, #ab47bc, #8e24aa, #6a1b9a, #4a148c, #6a1b9a, #8e24aa, #ab47bc, #ce93d8, #e1bee7)"
    },
    "Notre Dame": {
        "domain": "nd.edu",
        "example-email": "jsmith1",
        "location-options": ["ORD", "MDW", "SBN"],
        "pickup-spots": [
            {"name": "Library Circle", "coordinates": {"lat": 41.70242649812634, "lng": -86.23322455402543}},
            {"name": "Morris Inn", "coordinates": {"lat": 41.69823561402338, "lng": -86.23901423189277}},
            {"name": "St Liam East Lot", "coordinates": {"lat": 41.704843314794985, "lng": -86.2378623364319}},
        ],
        "timezone": "America/New_York",
        "timezone-short": "EST",
        "default-spot": "Library Circle",
        "color": "#4e2a84",
        "gradient": "linear-gradient(45deg, #e1bee7, #ce93d8, #ab47bc, #8e24aa, #6a1b9a, #4a148c, #6a1b9a, #8e24aa, #ab47bc, #ce93d8, #e1bee7)"
    },
    // "Tufts University": {
    //     "domain": "tufts.edu",
    //     "example-email": "bob.smith",
    //     "location-options": ["BOS"],
    //     "pickup-spots": ["Carmichael Hall"],
    //     "default-spot": "Carmichael Hall",
    //     "color": "#3e8ede",
    //     "gradient": "linear-gradient(to right, #3e8ede, #3172ae)",
    // },
    // "Tulane University" : {
    //     "domain": "tulane.edu",
    //     "example-email": "bsmith",
    //     "location-options": ["MSY"],
    //     "pickup-spots": ["LBC"],
    //     "default-spot": "LBC",
    //     "color": "#336666",
    //     "gradient": "linear-gradient(to right, #336666, #71c5e8)",
    // },
    "University of California, Berkeley": {
        "domain": "berkeley.edu",
        "example-email": "bobsmith2027",
        "location-options": ["SFO", "OAK"],
        "pickup-spots": [
            {"name": "Crescent Lawn Loop", "coordinates": {"lat": 37.87180526985951, "lng": -122.26508878266601}},
            {"name": "North Gate Hall", "coordinates": {"lat": 37.87507768873935, "lng": -122.2599539309889}},
            {"name": "South Gate Apartments", "coordinates": {"lat": 37.867897841160755, "lng": -122.25781654404568}},
        ],
        "timezone": "America/Los_Angeles",
        "timezone-short": "PST",
        "default-spot": "Crescent Lawn Loop",
        "color": "#4e2a84",
        "gradient": "linear-gradient(45deg, #e1bee7, #ce93d8, #ab47bc, #8e24aa, #6a1b9a, #4a148c, #6a1b9a, #8e24aa, #ab47bc, #ce93d8, #e1bee7)"
    },
    "University of Chicago": {
        "domain": "chicago.edu",
        "example-email": "bobsmith",
        "location-options": ["ORD", "MDW"],
        "pickup-spots": [
            {"name": "The ISAC Museum", "coordinates": {"lat": 41.789599995761634, "lng":-87.59798931795949}},
            {"name": "Max P. Central (Yellow Building)", "coordinates": {"lat": 41.79330574645208, "lng":-87.59975389142114}},
            {"name": "Cathey", "coordinates": {"lat": 41.78520612690594, "lng":-87.60109153460455}},
            {"name": "I-House", "coordinates": {"lat": 41.78798296520818, "lng":-87.59089746050968}},
        ],
        "timezone": "America/Chicago",
        "timezone-short": "CST",
        "default-spot": "The ISAC Museum",
        "color": "#800000",
        "gradient": "linear-gradient(to right, #800000, #767676)",
    },
    "University of Southern California": {
        "domain": "usc.edu",
        "example-email": "smithbob",
        "location-options": ["LAX"],
        "pickup-spots": [
            {"name": "USC Village Target", "coordinates": {"lat": 34.0256939, "lng": -118.2841789}},
            {"name": "USC Village Honors Dining Hall", "coordinates": {"lat": 34.0256347, "lng": -118.2865625}},
            {"name": "EVK Dining Hall", "coordinates": {"lat": 34.0210034, "lng": -118.2819102}},
            {"name": "Parking Lot 6", "coordinates": {"lat": 34.0218212, "lng": -118.2912725}},
        ],
        "timezone": "America/Los_Angeles",
        "timezone-short": "PST",
        "default-spot": "USC Village Target",
        "color": "#4e2a84",
        "gradient": "linear-gradient(45deg, #e1bee7, #ce93d8, #ab47bc, #8e24aa, #6a1b9a, #4a148c, #6a1b9a, #8e24aa, #ab47bc, #ce93d8, #e1bee7)"
    },
    "University of Virginia": {
        "domain": "virginia.edu",
        "example-email": "ab1cd",
        "location-options": ["CHO", "RIC"],
        "pickup-spots": [
            {"name": "AFC Parking Lot", "coordinates": {"lat": 38.033128, "lng": -78.514065}},
            {"name": "Runk Dining Hall", "coordinates": {"lat": 38.029114, "lng": -78.519258}},
            {"name": "Student Health", "coordinates": {"lat": 38.030143, "lng": -78.504530}},
            {"name": "Lambeth Residents Parking Lot", "coordinates": {"lat": 38.041890, "lng": -78.505030}},
        ],
        "timezone": "America/New_York",
        "timezone-short": "EST",
        "default-spot": "AFC Parking Lot",
        "color": "#4e2a84",
        "gradient": "linear-gradient(45deg, #e1bee7, #ce93d8, #ab47bc, #8e24aa, #6a1b9a, #4a148c, #6a1b9a, #8e24aa, #ab47bc, #ce93d8, #e1bee7)"
    },
    // "University of Illinois at Chicago": {
    //     "domain": "uic.edu",
    //     "example-email": "bobsmith",
    //     "location-options": ["ORD", "MDW"],
    //     "pickup-spots": ["Student Center East"],
    //     "default-spot": "Student Center East",
    //     "color": "#d50032",
    //     "gradient": "linear-gradient(to right, #d50032, #001e62)",
    // },
    // "William & Mary": {
    //     "domain": "wm.edu",
    //     "example-email": "bsmith",
    //     "location-options": ["RIC", "ORF"],
    //     "pickup-spots": ["Sadler Center"],
    //     "default-spot": "Sadler Center",
    //     "color": "#115740",
    //     "gradient": "linear-gradient(to right, #115740, #b9975b)",
    // }
}

export const collegeOptions = [
    // { value: "Boston College", label: "Boston College" },
    // { value: "Boston University", label: "Boston University" },
    { value: "Case Western Reserve University", label: "Case Western Reserve University" },
    // { value: "DePaul University", label: "DePaul University" },
    { value: "Duke University", label: "Duke University" },
    // { value: "Harvard University", label: "Harvard University" },
    // { value: "Illinois Institute of Technology", label: "Illinois Institute of Technology" },
    // { value: "Northeastern University", label: "Northeastern University" },
    // { value: "Northern Illinois University", label: "Northern Illinois University" },
    { value: "Northwestern University", label: "Northwestern University" },
    { value: "Notre Dame", label: "Notre Dame" },
    // { value: "Tufts University", label: "Tufts University" },
    // { value: "Tulane University", label: "Tulane University"},
    { value: "University of California, Berkeley", label: "University of California, Berkeley" },
    { value: "University of Chicago", label: "University of Chicago" },
    { value: "University of Southern California", label: "University of Southern California" },
    { value: "University of Virginia", label: "University of Virginia" },
    // { value: "University of Illinois at Chicago", label: "University of Illinois at Chicago" },
    // { value: "William & Mary", label: "William & Mary"}
]