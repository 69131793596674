import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { AiOutlineClose, AiOutlineMenu } from "react-icons/ai";
import { collegeMap } from "../assets/colleges.js";
import logo from "../assets/7.png";

function Navbar() {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(!open);
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 700) {
        setOpen(false);
      }
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const accentColor = localStorage.getItem("accentColor")
  console.log(accentColor)
  return (
    // Desktop Navbar
    <div
    className="min-h-[50px] text-white overflow-y-hidden top-0 left-0 right-0 flex justify-between items-center z-4 w-screen h-[15vh] px-7 text-[15px] font-medium">
      <h1
      className="text-2xl m-5">
        <Link to="/" className="hover:text-[rgba(0,0,0,0.5)]">
          SPLIT
        </Link>
      </h1>
      <ul className="hidden md:flex items-center justify-center gap-x-4">
        <li className="p-4">
          <Link
            to="/pickup"
            className="hover:text-[rgba(0,0,0,0.5)] rounded-sm bg-transparent inline-block outline-none py-2"
          >
            Pick Up Spots
          </Link>
        </li>
        <li className="p-4">
          <Link
            to="/faq"
            className="hover:text-[rgba(0,0,0,0.5)] rounded-sm bg-transparent inline-block outline-none py-2"
          >
            FAQs
          </Link>
        </li>
        <li className="p-4">
          <Link
            to="/contact"
            className="hover:text-[rgba(0,0,0,0.5)] rounded-sm bg-transparent inline-block outline-none py-2"
          >
            Contact
          </Link>
        </li>
        <li className="p-4">
          <Link
            to="/"
            className=" bg-purple-900 rounded-full inline-block outline-none px-4 py-1 hover:bg-white hover:text-purple-900"
          >
            Ride Now!
          </Link>
        </li>
      </ul>
      {/* Mobile Navbar */}
      <div onClick={handleOpen} className="block  md:hidden">
        {open ? <AiOutlineClose size={20} /> : <AiOutlineMenu size={20} />}
      </div>
      <div
        className={
          open
            ? "fixed left-0 top-0 z-10 w-[60%] h-full bg-[#C496EE] ease-in-out duration-500"
            : "fixed left-[-100%] z-10"
        }
      >
        <h1 className="w-full text-3xl font-bold text-white m-4">
          <Link className="hover:text-purple-500" to="/" onClick={handleOpen}>
            SPLIT
          </Link>
        </h1>
        <ul className="uppercase border-b border-gray-300">
          <Link
            to="/pickup"
            onClick={handleOpen}
            className="block p-4 border-b border-gray-300 hover:text-purple-800"
          >
            Pick Up Spots
          </Link>
          <Link
            to="/faq"
            onClick={handleOpen}
            className="block p-4 border-b border-gray-300 hover:text-purple-800"
          >
            FAQs
          </Link>
          <Link
            to="/contact"
            onClick={handleOpen}
            className="block p-4 hover:text-purple-800"
          >
            Contact
          </Link>
        </ul>
      </div>
    </div>
  );
}

export default Navbar;
