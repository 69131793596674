import React, { useState, useEffect } from 'react';
import { config } from '../Config';
import { PublicClientApplication } from '@azure/msal-browser';
import microsoftIcon from '../assets/Microsoft_logo.png';

const VerifyWithMicrosoftButton = ({setMicrosoftVerified, microsoftVerified, changeName, changeEmail, domain}) => {
    const msalConfig = {
        auth: {
            clientId: config.appId,
            redirectUri: config.redirectUri
        },
        system: {
            allowNativeBroker: true,
        },
    };

    const [msalInstance, setMsalInstance] = useState(null);
    const [user, setUser] = useState(null);

    useEffect(() => {
        if (user) {
            if (user.email.endsWith(domain)) {
                setMicrosoftVerified(true);
                changeEmail(user.email);
                changeName(user.firstName + " " + user.lastName);
            } else {
                alert("you must verify with school-issued email");
                setUser(null);
            }
        }
    }, [user]);

    useEffect(() => {
        const initializeMsal = async () => {
            const instance = new PublicClientApplication(msalConfig);
            await instance.initialize();
            await instance.handleRedirectPromise();
            setMsalInstance(instance);
        };

        initializeMsal();
    }, []);

    const getUserInfo = async (accessToken) => {
        const response = await fetch('https://graph.microsoft.com/v1.0/me', {
            headers: {
                Authorization: `Bearer ${accessToken}`
            }
        });
    
        if (response.ok) {
            const userInfo = await response.json();
            return userInfo;
        } else {
            throw new Error('Failed to fetch user information from Microsoft Graph API');
        }
    };

    const handleLogin = async () => {
        try {
            if (msalInstance) {
                const authResponse = await msalInstance.loginPopup({
                    scopes: config.scopes
                });
    
                if (authResponse && authResponse.accessToken) {
                    const userInfo = await getUserInfo(authResponse.accessToken);
                    console.log('User information:', userInfo);
                    setUser({
                        email: userInfo.mail,
                        firstName: userInfo.givenName,
                        lastName: userInfo.surname,
                    });
                } else {
                    console.error('Authentication response does not contain access token.');
                }
            } else {
                console.error('MSAL instance not initialized.');
            }
        } catch (error) {
            console.error('Error logging in:', error);
        }
    };

    const logOut = (event) => {
        event.preventDefault();
        setUser(null);
        setMicrosoftVerified(false);
    };

    return (
        <div className="w-full">
            {microsoftVerified ? (
                <button 
                className="w-[100%] bg-white hover:bg-blue-700 text-black font-medium py-2 px-4 border border-black rounded-3xl"
                onClick={logOut}>
                    <div className="flex gap-x-4 justify-center items-center">
                        <img className="w-6 h-6" src={microsoftIcon}></img>
                        <p> Log out of Microsoft</p>
                    </div>
                </button>
            ) : (
            <button 
            className="w-[100%] bg-white hover:bg-blue-700 text-black font-medium py-2 px-4 border border-black rounded-3xl"
            onClick={handleLogin}>
                <div className="flex gap-x-4 justify-center items-center">
                    <img className="w-6 h-6" src={microsoftIcon}></img>
                    <p> Verify with <span className="font-bold">School-issued</span> Email</p>
                </div>
            </button>
            )}
        </div>
    );
};

export default VerifyWithMicrosoftButton;
