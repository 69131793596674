import React from 'react';

const PaymentFailurePage = () => {
    return (
        <div className="flex justify-center items-center">
            <div className="bg-purple-500 p-5">
                <p className="text-5xl text-white">Sorry! Your payment did not go through, please try again!</p>
            </div>
        </div>
    )};

export default PaymentFailurePage;