import React, { useState , useEffect } from 'react';
import { useParams } from 'react-router-dom';
import GoogleMap from "../components/GoogleMap";
import { collegeMap } from '../assets/colleges';
import googleMapsLogo from '../assets/google-maps-logo.png';

const PickUpPage = () => {

    const [selectedPickupSpot, setSelectedPickupSpot] = useState(null);
    const { spot } = useParams();


    const pickupSpots = Object.values(collegeMap).flatMap((college) =>
    college["pickup-spots"].map((spot) => ({
            name: spot.name,
            location: {
                lat: spot.coordinates.lat,
                lng: spot.coordinates.lng
            },
        }))
    );

    const pickupNames = Object.values(collegeMap).flatMap((college) =>
        college["pickup-spots"].map((spot) => (
            spot.name
        ))
    );

    useEffect(() => {
        if (spot && pickupNames.includes(spot)) {
            const selectedSpot = pickupSpots.find((place) => place.name === spot);
            setSelectedPickupSpot(selectedSpot);
        }
    }, [spot]);
    
    const handleSelectChange = (event) => {
        const selectedSpot = pickupSpots.find((spot) => spot.name === event.target.value);
        setSelectedPickupSpot(selectedSpot);
        console.log("select changed");
    };

    return (
        <div className="w-[90vw] lg:w-[75vw] mx-auto m-8 p-6 bg-white rounded-md shadow-md">
            <label htmlFor="pickupSpot" className="block text-sm font-medium text-gray-600">
                Select Pickup Spot:
            </label>
            <select
                id="pickupSpot"
                onChange={handleSelectChange}
                value={selectedPickupSpot ? selectedPickupSpot.name : ''}
                className="mt-1 p-2 border rounded-md"
            >
                <option value="" disabled>
                Select a spot
                </option>
                {pickupSpots
                    .sort((a, b) => a.name.localeCompare(b.name)) // Sort the pickupSpots array by spot name
                    .map((spot) => (
                <option key={spot.name} value={spot.name}>
                            {spot.name}
                </option>
                ))}
            </select>

            {selectedPickupSpot ? (
                <div className="mt-4">
                <h2 className="text-xl font-semibold">{selectedPickupSpot.name}</h2>
                {/* <p className="text-gray-600">
                    Location: {selectedPickupSpot.location.lat}, {selectedPickupSpot.location.lng}
                </p> */}
                {/* Integrate Google Maps here using the selectedPickupSpot.location coordinates */}
                <GoogleMap lat={selectedPickupSpot.location.lat} lng={selectedPickupSpot.location.lng} />
                </div>
            ) : (
                <div className="mt-4 flex justify-center items-center w-full h-full">
                    {/* <p className="text-gray-600">
                        Location: {selectedPickupSpot.location.lat}, {selectedPickupSpot.location.lng}
                    </p> */}
                    {/* Integrate Google Maps here using the selectedPickupSpot.location coordinates */}
                    <img src={googleMapsLogo} className='h-1/2'/>
                </div>
            )}
        </div>
)};

export default PickUpPage;