import React from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css'; // Import the default CSS

const CustomDatePicker = ({ date, early, selected, handleChange, tomorrow , earliest }) => {
  
  const minDate = new Date();
  minDate.setDate(minDate.getDate() + 1)
  const maxDate = new Date();
  maxDate.setDate(maxDate.getDate() + 182)

  const minTime = new Date();
  if (tomorrow && tomorrow.getDate() === minDate.getDate()) {
    minTime.setHours(minTime.getHours() + 24);
  } else {
    minTime.setHours(0 , 0, 0, 0);
  }
  // minTime.setHours(minTime.getHours() + 24);
  const maxTime = new Date();
  maxTime.setHours(23, 59, 59, 999);

  return (
    <div className={`text-black w-full bg-white p-2 rounded-md shadow-lg outline-none focus:ring-2 focus:ring-black`}>
      {date ? (
        <DatePicker
          className="max-w-[4/5]"
          name="rideDate"
          required
          showPopperArrow={true}
          autoComplete="off"
          selected={selected}
          placeholderText="MM/DD/YYYY"
          onChange={(event) => handleChange(event, 6)}
          minDate={minDate}
          maxDate={maxDate}
          scrollableMonthYearDropdown
          popperPlacement="bottom"
        />
      ) : (
        <DatePicker
          className="max-w-[4/5]"
          showTimeSelect
          required
          showPopperArrow={false}
          showTimeSelectOnly
          placeholderText="-- : --"
          timeIntervals={15}
          timeCaption="Time"
          autoComplete="off"
          dateFormat="h:mm aa"
          timeFormat="h:mm aa"
          selected={selected}
          {...(early
            ? {
                minTime: minTime,
                maxTime: maxTime
              }
            : {
                minTime: earliest || minTime,
                maxTime: maxTime
            })}
          onChange={(event) => {
            if (early) {
              handleChange(event, 7);
            } else {
              handleChange(event, 8);
            }
          }}
        />
      )}
    </div>
  );
};

export default CustomDatePicker;
