import googleIcon from '../assets/google-icon.png';
import React, { useState, useEffect , useRef} from 'react';
import { googleLogout, useGoogleLogin, GoogleOAuthProvider} from '@react-oauth/google';
import axios from 'axios';


const VerifyWithGoogleButton = ({setGoogleVerified, googleVerified, changeName, changeEmail, domain}) => {

  const [ user, setUser ] = useState([]);
  const [ profile, setProfile ] = useState([]);
  const textRef = useRef(null);

  const login = useGoogleLogin({
      onSuccess: (codeResponse) => {
        // console.log("code response ");
        // console.log(codeResponse);
        if (codeResponse) {
          // setGoogleVerified(true);
          setUser(codeResponse);
        } else {
          // logic for asking to verify with student email
          textRef.current.innerText = "Google not responding";
          // textRef.current.innerText = "You must verify with school-issued email";
        }
      },
      onError: (error) => console.log('Login Failed:', error)
  });

  useEffect(
      () => {
          if (user) {
              axios
                  .get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user.access_token}`, {
                      headers: {
                          Authorization: `Bearer ${user.access_token}`,
                          Accept: 'application/json'
                      }
                  })
                  .then((res) => {
                      // console.log("response email");
                      // console.log(res.data.email);
                      if (res.data.email.endsWith(domain)) {
                        setGoogleVerified(true);
                        setProfile(res.data);
                      } else {
                        // logic for asking to verify with student email
                        textRef.current.innerText = "You must verify with school-issued email";
                        setUser([]);
                      }
                  })
                  .catch((err) => console.log(err));
          }
      },
      [ user ]
  );

  useEffect(() => {
    if (profile.length !== 0) {
      changeName(profile.name);
      changeEmail(profile.email);
    }
    else {
      changeName(null);
      changeEmail(null);
    }
  }, [profile]);

  const logOut = (event) => {
      event.preventDefault();
      googleLogout();
      setProfile([]);
      setGoogleVerified(false);
      // changeFunc(null, false);
  };

  return (
    // <GoogleOAuthProvider clientId="950716568830-qdufri34l2mi9dg1kbj4r443abaniv63.apps.googleusercontent.com">
      <div>
        {googleVerified ? (
          <button
            onClick={(event) => logOut(event)}
            className="w-[100%] bg-white hover:bg-blue-700 text-black font-medium py-2 px-4 rounded-3xl"
          >
            <div className="flex gap-x-4 justify-center items-center">
              <img className="w-6 h-6" src={googleIcon}></img>
              <p> Log Out of Google Account</p>
            </div>
          </button>
        ) : (
          <div className="flex flex-col justify-center items-center gap-y-4">
            <div ref={textRef} className="text-xl">Please verify with Google or Microsoft to proceed</div>
            <button
              onClick={(event) => {
                event.preventDefault();
                login();}}
              className="w-[100%] bg-white hover:bg-blue-700 text-black font-medium py-2 px-4 border border-black rounded-3xl"
            >
              <div className="flex gap-x-4 justify-center items-center">
                <img className="w-6 h-6" src={googleIcon}></img>
                <p> Verify with <span className="font-bold">School-issued</span> Email</p>
              </div>
            </button>
          </div>
        )}
      </div>
    // </ GoogleOAuthProvider>
    // {/* // <GoogleLogin className="w-[100%] bg-white hover:bg-blue-700 text-black font-medium py-2 px-4 rounded-3xl" onSuccess={responseMessage} onError={errorMessage} />
    // // <button
    // //   onClick={console.log("here")}
    // //   className="w-[100%] bg-white hover:bg-blue-700 text-black font-medium py-2 px-4 rounded-3xl"
    // // >
    // //   <div className="flex gap-x-4 justify-center items-center">
    // //     <img className="w-6 h-6" src={googleIcon}></img>
    // //     <p> Verify with Google</p>
    // //   </div>
    // // </button> */}
  );
};

export default VerifyWithGoogleButton;