import React from "react";

export const faqData = [
        {
          question: "What is SPLIT?",
          answer:
            "SPLIT matches students at the same university traveling to the same airport at the same time. We call rides to centralized pickup locations so you can SPLIT an Uber with fellow students and save 40-65%.",
        },
        {
          question: "Who made SPLIT?",
          answer:
            "Split is made by a group of Northwestern students. Every break we noticed the consistent shared uber request messages flooding the class-wide groupchats and we knew there had to be a better way. That’s why we created SPLIT— a service with the goal to make transportation to the airport as easy and affordable as possible for all students.",
        },
        {
          question: "How can I use SPLIT?",
          answer:
            "Simply fill out our form with your college, pickup time, and preferred pickup location and we do the rest! We will update you via text messages up until your ride time. Then go to your assigned pickup location at your ride time and go to the airport!",
        },
        {
          question: "After I fill out the form, where will I find my ride information and payment link?",
          answer:
            "All this information will be texted to you after you fill out the form and before your ride time.",
        },
        {
          question: "How much money will I save?",
          answer:
            "You will save between 40-65% of your usual ride cost depending on how large of a group you are matched with.",
        },
        {
          question: "How is the price calculated?",
          answer: "The price you will pay for a SPLIT ride is: price of uber / size of your group + 4.62% SPLIT fee.",
        },
        {
          question: "Who will I be riding with?",
          answer: "You will only be riding with people affiliated with your university. Our system uses email verification to ensure your matches have a valid email address associated with your university. ",
        },
        {
          question: "How can I contact SPLIT?",
          answer: "Send us a message at our contact us page or email info@splitrideshare.com.",
        },
        {
          question: "What if my flight gets canceled?",
          answer: "If you do not take your ride because your flight is canceled or delayed for more than 3 hours, please send proof of cancellation/delay (boarding pass, voucher, any other travel documents) to info@splitrideshare.com, and we will refund your ride.",
        }
];