import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import {
  collection,
  addDoc,
  serverTimestamp,
  Timestamp,
} from "firebase/firestore";
// import { db } from "../firebase2";

export default function ThankYouPage() {
  // const [dataAdded, setDataAdded] = useState(false);
  // const location = useLocation();

  // useEffect(() => {
  //   const getQueryParam = (param) => {
  //     const searchParams = new URLSearchParams(location.search);
  //     return searchParams.get(param);
  //   };
  //   const name = getQueryParam("name") || "";
  //   const number = getQueryParam("number") || "";
  //   const college = getQueryParam("college") || "";
  //   const email = getQueryParam("email") || "";
  //   const route = getQueryParam("route") || "";
  //   const rideDateStr = getQueryParam("rideDate") || "";
  //   const earliestDepartureTimeStr =
  //     getQueryParam("earliestDepartureTime") || "";
  //   const latestDepartureTimeStr = getQueryParam("latestDepartureTime") || "";

  //   // Parse the date and time strings back to Date objects
  //   const rideDate = new Date(rideDateStr);
  //   const earliestDepartureTimeParts = earliestDepartureTimeStr.split(":");
  //   const latestDepartureTimeParts = latestDepartureTimeStr.split(":");
  //   const earliestDepartureTime = new Date(rideDate);
  //   earliestDepartureTime.setHours(parseInt(earliestDepartureTimeParts[0], 10));
  //   earliestDepartureTime.setMinutes(
  //     parseInt(earliestDepartureTimeParts[1], 10)
  //   );

  //   const latestDepartureTime = new Date(rideDate);
  //   latestDepartureTime.setHours(parseInt(latestDepartureTimeParts[0], 10));
  //   latestDepartureTime.setMinutes(parseInt(latestDepartureTimeParts[1], 10));
  //   // If earliest time is greater than latest time, it means the ride goes into the next day
  //   // So we add 1 day (86400 seconds) to the latest time
  //   if (earliestDepartureTime > latestDepartureTime) {
  //     latestDepartureTime.setDate(latestDepartureTime.getDate() + 1);
  //   }

  //   const earliest = Timestamp.fromDate(earliestDepartureTime);
  //   const latest = Timestamp.fromDate(latestDepartureTime);
  

  //   const addDataToFirestore = async () => {
  //     try {
  //       // Create the docData object using the extracted query parameters
  //       const docData = {
  //         active: true,
  //         college: college,
  //         confirmation: false,
  //         earliest: earliest,
  //         email: email,
  //         followup: false,
  //         grouped: false,
  //         latest: latest,
  //         reminded: false,
  //         name: name,
  //         number: number.replace(/\D/g, ""),
  //         paid: false,
  //         session_id: null,
  //         route: route,
  //         timestamp: serverTimestamp(),
  //       };

  //       // Add the document to the Firestore collection
  //       await addDoc(collection(db, "Staging Responses"), docData); // Fix collection name
  //       setDataAdded(true);
  //     } catch (error) {
  //       console.error("Error adding data to Firestore:", error);
  //       // Handle the error here if necessary
  //     }
  //   };

  //   // Call the asynchronous function
  //   addDataToFirestore();
  // }, [location.search]);

  // if (!dataAdded) {
  //   return (
  //     <div className="flex justify-center items-center">
  //       <div className="bg-purple-500 p-5">
  //         <p className="text-5xl text-white">
  //           We are in the process of verifying your student status!
  //         </p>
  //       </div>
  //     </div>
  //   );
  // }

  return (
    <div className="flex justify-center">
      <div className="bg-purple-500 p-8 rounded-lg shadow-lg text-center text-white w-3/4 h-fit mt-20">
        <h1 className="text-4xl mb-6">Thank you for your submission!</h1>
        <p className="text-lg">
          Your form has been received and we will be in touch with you shortly.
        </p>
      </div>
    </div>
  );
}
