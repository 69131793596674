import React from 'react';

const PaymentSuccessPage = () => {
    return (
        <div className="flex justify-center items-center">
            <div className="bg-purple-500 p-5">
                <p className="text-5xl text-white">Payment success! Thank you for using SPLIT!</p>
            </div>
        </div>
    )};

export default PaymentSuccessPage;