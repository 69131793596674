import React, { useState , useRef , useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { collegeOptions } from "../assets/colleges.js";

export default function CustomDropdown() {
  const [isOpen, setIsOpen] = useState(false);
  const [newCollegeOptions, setNewCollegeOptions] = useState(collegeOptions);
  const [selectedCollege, setSelectedCollege] = useState(null);
  const inputRef = useRef(null);
  const wrapperRef = useRef(null);
  const navigate = useNavigate();

  const handleOptionClick = (collegeName) => {
    console.log(collegeName);
    navigate(`/home/${collegeName}`);
  };

  const handleToggle = () => {
    setIsOpen(!isOpen);
    if (!isOpen) {
        inputRef.current.focus();
        inputRef.current.removeAttribute('placeholder');
    } else {
        inputRef.current.setAttribute('placeholder', 'Select your college...');
    }
  };

  const handleClickOutside = (event) => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        if (isOpen) {
            setIsOpen(!isOpen);
        }
        inputRef.current.setAttribute('placeholder', 'Select your college...');
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [handleClickOutside]);


  const handleTextareaChange = (event) => {
    setSelectedCollege(null);
    const inputValue = event.target.value.toLowerCase();
    const filteredColleges = collegeOptions.filter(option => option.value.toLowerCase().startsWith(inputValue));
    setNewCollegeOptions(filteredColleges);
  };

  return (
    <div ref={wrapperRef} className="relative inline-block text-left w-full px-5">
      <div className="flex justify-between items-center w-full">
        <button
          onClick={handleToggle}
          type="button"
          className="inline-flex justify-between items-center w-full rounded-md border border-none bg-white text-sm font-medium text-gray-300 focus:outline-none focus:border-blue-500 focus:shadow-outline-blue active:bg-gray-50 active:text-gray-800"
          aria-haspopup="listbox"
          aria-expanded={isOpen}
        >
            <div className="flex gap-x-4 w-full">
                <i class="fa-solid fa-magnifying-glass text-gray-400 text-xl"></i>
                <input
                className="text-black focus:outline-none w-full text-xs lg:text-sm"
                ref={inputRef}
                placeholder='Select your college...'
                value={selectedCollege}
                onChange={handleTextareaChange}
                />
            </div>
            <div className="border-l-2 border-gray-300 h-8 w-12 flex justify-end items-center"><i class="text-gray-300 fa-solid fa-angle-down text-xl"></i></div>
        </button>
      </div>
      

      {isOpen && (
        <div className="origin-top-right absolute left-[10%] lg:left-0 mt-2 w-[80%] lg:w-full rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none" role="menu" aria-orientation="vertical" aria-labelledby="dropdownButton" tabIndex="-1">
          {newCollegeOptions.map((college) => (
            <button
              key={college.value}
              onClick={() => handleOptionClick(college.value)}
              className="block px-4 py-2 text-sm text-gray-700 w-full text-left hover:bg-gray-400"
              role="menuitem"
            >
              {college.value}
            </button>
          ))}
        </div>
      )}
    </div>
    
  );
};