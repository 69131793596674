import React from "react";

const TermsPage = () => {
    return (
        <div className="flex justify-center items-center p-12">
            <div className="bg-[rgba(0,0,0,0.5)] text-white rounded-lg p-5 w-[75vw] space-y-4 text-lg">
                <h1 className="text-3xl md:text-7xl pb-5 underline">Split Terms and Conditions</h1>
                <p>Last Updated: July 10, 2023</p>
                <p>
                These Terms of Service constitute a legally binding agreement (the "Agreement") between you and Split, Inc., its parents, subsidiaries, representatives, affiliates, officers, and directors (collectively, "Split," "we," "us," or "our") governing your use of the Split applications, websites, technology, facilities, and platform (collectively, the "Split Platform").
                </p>
                <p>
                PLEASE BE ADVISED: THIS AGREEMENT CONTAINS PROVISIONS THAT GOVERN HOW CLAIMS BETWEEN YOU AND SPLIT CAN BE BROUGHT (SEE SECTION 17 BELOW).<span className="font-bold"> THESE PROVISIONS WILL, WITH LIMITED EXCEPTION, REQUIRE YOU TO: (1) WAIVE YOUR RIGHT TO A JURY TRIAL, AND (2) SUBMIT CLAIMS YOU HAVE AGAINST SPLIT TO BINDING AND FINAL ARBITRATION ON AN INDIVIDUAL BASIS, NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY CLASS, GROUP, OR REPRESENTATIVE ACTION OR PROCEEDING. </span> AS A DRIVER OR DRIVER APPLICANT, YOU HAVE AN OPPORTUNITY TO OPT OUT OF ARBITRATION WITH RESPECT TO CERTAIN CLAIMS AS PROVIDED IN SECTION 17.
                </p>
                <p>
                By entering into this Agreement, and/or by using or accessing the Split Platform, you expressly acknowledge that you understand this Agreement (including the dispute resolution and arbitration provisions in Section 17) and accept all of its terms. IF YOU DO NOT AGREE TO BE BOUND BY THE TERMS AND CONDITIONS OF THIS AGREEMENT, YOU MAY NOT USE OR ACCESS THE SPLIT PLATFORM OR ANY OF THE SERVICES PROVIDED THROUGH THE SPLIT PLATFORM. If you use the Split Platform in another country, you agree to be subject to Split's terms of service for that country. If you are accessing or using the Split Platform to access or use Split Business services on behalf of an organization contracted with Split, and you are not accessing or using the Split Platform as a Rider or Driver, your access and use are governed by the contract between Split and your organization.
                </p>
                <p>
                When using the Split Platform, you also agree to conduct yourself in accordance with our Community Guidelines, which shall form part of this Agreement between you and Split.
                </p>
            </div>
        </div>
    )};

export default TermsPage;