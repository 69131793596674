import React from "react";
import { useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import HomePage from "./components/HomePage";
import LogoPage from "./components/LogoPage";
import ThankYouPage from "./components/ThankYouPage";
import AboutPage from "./components/AboutPage";
import FAQPage from "./components/FAQPage";
import ContactPage from "./components/ContactPage";
import TermsPage from "./components/TermsPage";
import MessageThankYouPage from "./components/MessageThankYouPage";
import PaymentSucccessPage from "./components/PaymentSuccessPage";
import PaymentFailurePage from "./components/PaymentFailurePage";
import PickUpPage from "./components/PickUpPage";
import { collegeMap } from "./assets/colleges";

import Footer from "./components/Footer";
import Navbar from "./components/Navbar";
import "./styles/App.css";
import "@fortawesome/fontawesome-free/css/all.min.css";

const App = () => {
  // useEffect(() => {
  //   const savedCollege = localStorage.getItem('college');

  //   if (savedCollege && collegeMap[savedCollege]) {
  //     const linearGradient = collegeMap[savedCollege].gradient;
  //     const accentColor = collegeMap[savedCollege].color;
  //     document.documentElement.style.setProperty('--linear-gradient', linearGradient);
  //     document.documentElement.style.setProperty('--acccent-color', accentColor);
  //   }
  // }, []);
  return (
    <Router>
      <div className="flex flex-col overflow-hidden min-h-screen bg-gradient-to-r from-purple-500 to-purple-900">
        <Navbar />
        <div className="flex justify-center w-full h-auto min-h-[100vh]">
          <Routes>
            <Route path="/" element={<LogoPage />} />
            <Route path="/home/:college" element={<HomePage />} />
            <Route path="/thankyou" element={<ThankYouPage />} />
            <Route path="/about" element={<AboutPage />} />
            <Route path="/faq" element={<FAQPage />} />
            <Route path="/contact" element={<ContactPage />} />
            <Route path="/terms" element={<TermsPage />} />
            <Route path="/messagethankyou" element={<MessageThankYouPage />} />
            <Route path="/paymentsuccess" element={<PaymentSucccessPage />} />
            <Route path="/paymentfailure" element={<PaymentFailurePage />} />
            <Route path="/pickup/:spot?" element={<PickUpPage />} />
          </Routes>
        </div>
        <Footer />
      </div>
    </Router>
  );
};

export default App;