import React, { useEffect } from 'react';

const GoogleMap = ({ lat, lng }) => {

  useEffect(() => {
    const map = new window.google.maps.Map(document.getElementById('map'), {
      center: { lat, lng },
      zoom: 12,
    });

    new window.google.maps.Marker({
      position: { lat, lng },
      map: map,
      title: 'Selected Pickup Spot',
    });
  }, [lat, lng]);

  return <div onClick={() =>
    window.open(
      `http://www.google.com/maps/place/${lat},${lng}`,
      "_blank"
    )
  } id="map" className="h-[300px] mt-[20px]"></div>;
};

export default GoogleMap;
