import React, { useState, useEffect , useRef} from "react";
import VerifyWithGoogleButton from "../components/GoogleAuthButton";
import VerifyWithEmailButton from "../components/EmailAuthButton";
import "react-datepicker/dist/react-datepicker.css";
import { useNavigate } from "react-router-dom";
import { sendSignInLinkToEmail } from "firebase/auth";
import { firestore } from "../firebase2";
import {addDoc , collection , serverTimestamp, Timestamp} from "@firebase/firestore";
import { collegeMap, collegeOptions } from "../assets/colleges.js";
import "../styles/index.css";
import CustomDatePicker from "../components/CustomDatePicker";
import { useParams } from 'react-router-dom';
import { GoogleOAuthProvider } from '@react-oauth/google';
import validator from "validator";
import VerifyWithMicrosoftButton from './MicrosoftAuthButton';

export default function HomePage() {
  const navigate = useNavigate();
  const { college } = useParams();
  const selectedCollegeValue = college;
  console.log("selected college value");
  console.log(selectedCollegeValue);
  const ref = collection(firestore, "Responses");
  const scrollTarget = useRef(null);
  const [selectedName, setSelectedName] = useState(null);
  const [selectedNumber, setSelectedNumber] = useState(null);
  const [selectedEmail, setSelectedEmail] = useState(null);
  const [selectedCollege, setSelectedCollege] = useState(selectedCollegeValue);
  const [selectedRoute, setSelectedRoute] = useState(null);
  const [selectedPickup, setSelectedPickup] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedEarliest, setSelectedEarliest] = useState(null);
  const [selectedLatest, setSelectedLatest] = useState(null);
  const [googleVerified, setGoogleVerified] = useState(false);
  const [microsoftVerified, setMicrosoftVerified] = useState(false);
  const [consent, setConsent] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);

  const [formData, setFormData] = useState({
    name: selectedName,
    number: selectedNumber,
    email: selectedEmail,
    route: selectedRoute,
    pickup: selectedPickup,
    college: selectedCollege,
    rideDate: selectedDate,
    earliestDepartureTime: selectedEarliest,
    latestDepartureTime: selectedLatest,
  });

  useEffect(() => {
    if (selectedCollege === "University of Virginia" || selectedCollege === "Duke University") {
      setMicrosoftVerified(true);
    }
  }, [selectedCollege]);

  useEffect(() => {
    setFormData({
      name: selectedName,
      number: selectedNumber,
      email: selectedEmail,
      route: selectedRoute,
      pickup: selectedPickup,
      college: selectedCollege,
      rideDate: selectedDate,
      earliestDepartureTime: selectedEarliest,
      latestDepartureTime: selectedLatest,
    });
    console.log(formData);
  }, [
    selectedName,
    selectedNumber,
    selectedEmail,
    selectedRoute,
    selectedPickup,
    selectedCollege,
    selectedDate,
    selectedEarliest,
    selectedLatest,
  ]);

  console.log(collegeMap[selectedCollege]["domain"]);

  const formatPhoneNumber = (phoneNumberString) => {
    const cleaned = ("" + phoneNumberString).replace(/\D/g, "");
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return "(" + match[1] + ") " + match[2] + "-" + match[3];
    }
    return phoneNumberString;
  };

  const handleChange = (event, number) => {
    if (number === 0) {
      setSelectedName(event.target.value);
    } else if (number === 1) {
      setSelectedNumber(formatPhoneNumber(event.target.value));
    } else if (number === 2) {
      setSelectedCollege(event.target.value);
      setSelectedName(null);
      setSelectedNumber(null);
      setSelectedEmail(null);
      setSelectedRoute(null);
      setSelectedPickup(null);
      setSelectedDate(null);
      setSelectedEarliest(null);
      setSelectedLatest(null);
      setGoogleVerified(false);
      setMicrosoftVerified(false);
      setConsent(false);
    } else if (number === 3) {
      setSelectedEmail(event.target.value);
    } else if (number === 4) {
      setSelectedPickup(event.target.value);
    } else if (number === 5) {
      setSelectedRoute(event.target.value);
    } else if (number === 6) {
      setSelectedDate(event);
      setSelectedEarliest(null);
      setSelectedLatest(null);
    } else if (number === 7) {
      console.log("ABC");
      setSelectedEarliest(event);
      setSelectedLatest(null);
    } else if (number === 8) {
      setSelectedLatest(event);
    } else if (number === 9) {
      setConsent(event.target.checked);
    }
  };

  const checkValidityOfPhoneNumber = (number) => {
    // Remove non-numeric characters from the number
    const cleanedNumber = number.replace(/\D/g, '');

    // Check if the cleanedNumber has exactly 10 digits
    if (cleanedNumber.length !== 10) {
        return false;
    }

    // Extract area code and the rest of the number
    const areaCode = cleanedNumber.slice(0, 3);
    const restOfNumber = cleanedNumber.slice(3);

    // Validate the phone number using validator library
    const isValidPhoneNumber = validator.isMobilePhone(`(${areaCode}) ${restOfNumber}`, 'any', { strictMode: false });

    return isValidPhoneNumber;
  };

  const pushToFirebase = async(e) => {

    const correctCollegeDomain = collegeMap[selectedCollege]["domain"];

    if (
          !formData.name ||
          !formData.number ||
          !formData.rideDate ||
          !formData.earliestDepartureTime ||
          !formData.latestDepartureTime ||
          !formData.email ||
          !formData.pickup
        ) {
          alert("Please fill out all fields");
          return;
        } else if (formData.number.length !== 14 ) {
          alert("Please enter the correct number of digits for a phone number");
          return;
        } else if (!checkValidityOfPhoneNumber(formData.number)) {
          alert("Please enter a valid phone number")
        } else if (formData.name.split(" ").length < 2) {
          alert("Please enter both your first and last name");
          return;
        } else if (!consent) {
          alert("Please consent to receiving sms from SPLIT");
          return;
        } else if (!googleVerified && !microsoftVerified) {
          alert("Please authenticate with either Google or Microsoft");
          return;
        } else if (!selectedEmail.endsWith(correctCollegeDomain)) {
          alert("Please authenticate with school-provided email");
        } else {
          console.log("pushing to firebase");
          console.log(e);
          e.preventDefault();

          console.log("ride data");
          console.log(formData);

          // Extract time from the earliest and latest times
          const earliestTimeHours = selectedEarliest.getHours();
          const earliestTimeMinutes = selectedEarliest.getMinutes();
          const latestTimeHours = selectedLatest.getHours();
          const latestTimeMinutes = selectedLatest.getMinutes();

          const { DateTime } = require("luxon");

          // Convert times to the Central Time Zone (CT)
          const centralTimezone = collegeMap[selectedCollege]["timezone"];

          // Create DateTime objects using the selected date and times
          const firstDateTime = DateTime.fromObject({
            year: selectedDate.getFullYear(),
            month: selectedDate.getMonth() + 1, // Note: Luxon month is 1-based
            day: selectedDate.getDate(),
            hour: earliestTimeHours,
            minute: earliestTimeMinutes
          }, { zone: centralTimezone });

          var secondDateTime = DateTime.fromObject({
            year: selectedDate.getFullYear(),
            month: selectedDate.getMonth() + 1,
            day: selectedDate.getDate(),
            hour: latestTimeHours,
            minute: latestTimeMinutes,
          }, { zone: centralTimezone }); 

          if (latestTimeHours < earliestTimeHours) {
            secondDateTime.plus({ days: 1 });
          }

          // Create Timestamp objects with Central Time Zone
          const earliest = Timestamp.fromMillis(firstDateTime.toMillis());
          const latest = Timestamp.fromMillis(secondDateTime.toMillis());

          console.log("earliest");
          console.log(earliest);
          console.log("latest");
          console.log(latest);

          // const rideDateTime = formatDate(formData.rideDate);
          // const earliest = formatTime(formData.earliestDepartureTime);
          // const latest = formatTime(formData.latestDepartureTime);

          console.log("final form data")
          console.log(formData);

          const docData = {
            active: true,
            college: selectedCollege,
            confirmation: false,
            earliest: earliest,
            email: selectedEmail,
            followup: false,
            grouped: false,
            latest: latest,
            reminded: false,
            name: selectedName,
            number: selectedNumber.replace(/\D/g, ""),
            paid: false,
            session_id: null,
            pickup: selectedPickup,
            route: selectedRoute,
            timestamp: serverTimestamp(),
          };
          try {
            addDoc(ref, docData);
            navigate("/thankyou");
          } catch (e) {
            console.log("Error:")
            console.log(e);
          }
        }


  }

  function formatDate(dateString) {
    if (!dateString) return "";
  
    const options = { weekday: "long", year: "numeric", month: "long", day: "numeric" };
    const formattedDate = new Date(dateString).toLocaleDateString(undefined, options);
  
    return formattedDate;
  }

  function isMobileDevice() {
    const mobileRegExp = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;
    return mobileRegExp.test(navigator.userAgent);
  }

  function scrollToTop() {
    window.scrollTo(0, 0);
  }

  function formatTime(dateObject) {
    if (!dateObject) return "";
  
    const options = { hour: "numeric", minute: "2-digit", hour12: true };
    const formatter = new Intl.DateTimeFormat("en-US", options);
    const formattedTime = formatter.format(dateObject);
  
    return `${formattedTime} ${collegeMap[selectedCollege]["timezone-short"]}`;
  }

  function getOffsetTop(element) {
    let offsetTop = 0;
  
    while (element) {
      if (isNaN(element.offsetTop)) {
        return offsetTop;
      }
      offsetTop += element.offsetTop;
      element = element.parentNode;
    }
  
    return offsetTop;
  }

  const scrollToTarget = () => {
    const targetElement = scrollTarget.current;
    const recur = getOffsetTop(targetElement);
    if (targetElement) {
      const targetPosition = recur;
      const startPosition = window.scrollY;
      const distance = targetPosition - startPosition;
      const duration = 1000;

      let startTimestamp;

      const animateScroll = (timestamp) => {
        if (!startTimestamp) {
          startTimestamp = timestamp;
        }

        const elapsed = timestamp - startTimestamp;
        const progress = Math.min(elapsed / duration, 1);
        const easedProgress = easeInOutQuad(progress);

        window.scrollTo(0, startPosition + distance * easedProgress);

        if (progress < 1) {
          requestAnimationFrame(animateScroll);
        }
      };

      requestAnimationFrame(animateScroll);
    }
  };

  const easeInOutQuad = (t) => (t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t);

  return (
    <div>
      <div className="m-5 lg:grid gap-0 bg-transparent lg:grid-cols-[2fr,5fr] flex flex-col w-screen justify-center items-center lg:p-5 ">
        <div className="h-[75vh] max-h-[600px] hidden lg:flex flex-col items-center justify-center p-8 text-lg max-w-[75vw] lg:max-w-none lg:text-xl bg-gray-50 rounded-l-[50px] shadow-lg space-y-5">
          <div className="relative h-[30%] w-[80%]">
              <i className="fa-solid fa-circle-xmark absolute text-[22px] -top-[11px] left-1/2 transform -translate-x-1/2 text-4xl text-red-500 z-10"></i>
              <div className="bg-white shadow-lg p-6 rounded-3xl text-gray-800 text-[15px] font-bold relative">
                <div>Ride to {collegeMap[selectedCollege]["location-options"][0]}</div>
                <div className="text-purple-700 text-[1.75rem] font-[650]">$50 - $60</div>
              </div>
            </div>
            <div className="relative h-[30%] w-[80%]">
              <i className="fa fa-check-circle absolute text-[22px] -top-[11px] left-1/2 transform -translate-x-1/2 text-4xl text-green-500 z-10"></i>
              <div className="bg-white shadow-lg p-6 rounded-3xl text-gray-800 text-[15px] font-bold relative">
                <div>SPLIT to {collegeMap[selectedCollege]["location-options"][0]}</div>
                <div className="text-purple-700 text-[1.75rem] font-[650]">$20 - $30</div>
              </div>
            </div>
            <p className="p-3 font-bold text-sm">Fill out form. We do the rest!</p>
        </div>
        <div className="relative items-center justify-center">
          <div className={`${googleVerified || microsoftVerified ? "hidden" : "origin-top-right absolute flex"} top-0 left-0 z-50 w-full h-full bg-black bg-opacity-50 lg:rounded-[75px] items-start lg:items-center justify-center`}>
            <div className="flex flex-col gap-y-4 justify-center items-center bg-white p-10 rounded-lg">
                <GoogleOAuthProvider clientId="950716568830-qdufri34l2mi9dg1kbj4r443abaniv63.apps.googleusercontent.com">
                  <VerifyWithGoogleButton setGoogleVerified={setGoogleVerified} changeName={setSelectedName} changeEmail={setSelectedEmail} domain={collegeMap[selectedCollege]["domain"]}/>
                </GoogleOAuthProvider>
                <VerifyWithMicrosoftButton setMicrosoftVerified={setMicrosoftVerified} microsoftVerified={microsoftVerified} changeName={setSelectedName} changeEmail={setSelectedEmail} domain={collegeMap[selectedCollege]["domain"]}/>
            </div>
          </div>

          <div className={`${showConfirmation ? "origin-top-right absolute flex" : "hidden"} top-0 left-0 z-50 w-full h-full bg-black bg-opacity-50 lg:rounded-[75px] items-start lg:items-center justify-center`}>
            <div ref={scrollTarget} className="flex flex-col gap-y-5 justify-center items-center bg-white p-10 rounded-xl">
                <h2 className="text-purple-950 text-4xl font-bold">Confirmation</h2>
                <p>Confirm your information is correct</p>
                <div className="gap-y-5 w-full flex flex-col justify-center items-start">
                  <div className="grid grid-cols-2 gap-2 w-full">
                    <p className="font-bold">Phone Number:</p>
                    <p>{selectedNumber}</p>
                  </div>
                  <div className="grid grid-cols-2 gap-2 w-full">
                    <p className="font-bold">Airport: </p>
                    <p>{selectedRoute}</p>
                  </div>
                  <div className="grid grid-cols-2 gap-2 w-full">
                    <p className="font-bold">Date: </p>
                    <p>{formatDate(selectedDate)}</p>
                  </div>
                  <div className="grid grid-cols-2 gap-2 w-full">
                    <p className="font-bold">Pickup Time: </p>
                    <p>{formatTime(selectedEarliest)} - {formatTime(selectedLatest)}</p>
                  </div>
                  <div className="grid grid-cols-2 gap-2 w-full">
                    <p className="font-bold">Pickup Spot: </p>
                    <p>{selectedPickup}</p>
                  </div> 
                </div>
                <div className="w-full space-y-2 flex flex-col items-center justify-center">
                  <button onClick={(event) => pushToFirebase(event)} className="w-[80%] text-white font-medium p-2 rounded-3xl bg-purple-700">Submit</button>
                  <button onClick={
                    (event) => {
                      event.preventDefault();
                      setShowConfirmation(false);
                    }
                  } className="w-[80%] text-white font-medium p-2 rounded-3xl bg-orange-700">Edit</button>
                </div>
            </div>
          </div>
          <form className="w-full p-5 bg-[rgba(0,0,0,0.5)] shadow-2xl outline-gray-900 lg:rounded-[75px]">
            <div className="p-10 space-y-4 text-white">
              <div className="text-4xl font-bold">Let's Ride!</div>
              <div className="text-sm space-y-2">
                <p>
                  MAKE SURE TO FILL OUT THE FORM 24 HOURS IN ADVANCE OF YOUR EARLIEST PICKUP TIME
                </p>
                <p>
                Welcome to SPLIT! Fill out your ride details and we will
                 send you a confirmation text 5-10 minutes after your submission.
                  From there will we update you on your matches and ride details via text
                </p>
              </div>
              <div className="block space-y-4 lg:space-y-0 lg:grid gap-4 lg:grid-cols-2">
                <div className="text-white">
                  <label htmlFor="" className="block mb-2 text-white">
                    <span className="text-bold text-lg text-red-600">*</span>{" "}
                    Your Name
                  </label>
                  {/* <textarea
                    className="w-full px-4 py-2 border border-gray-300 rounded-3xl bg-white text-black resize-none overflow-hidden whitespace-nowrap"
                    readOnly={true}
                    rows={1}
                    value={selectedName || "eg. Joe Smith"}
                  /> */}
                  <textarea
                    className="w-full px-4 py-2 border border-gray-300 rounded-3xl bg-white text-black resize-none overflow-hidden whitespace-nowrap"
                    readOnly={selectedCollege !== "University of Virginia" && selectedCollege !== "Duke University"}
                    rows={1}
                    value={selectedName}
                    placeholder={"eg. Joe Smith"}
                    onChange={(e) => setSelectedName(e.target.value)}
                  />
                  {/* <input
                    disabled
                    type="text"
                    required
                    value={selectedName || ""}
                    onChange={(event) => handleChange(event, 0)}
                    placeholder="eg. Joe Smith"
                    className="text-white w-full px-4 py-2 border border-gray-300 rounded-3xl focus:outline-none focus:border-purple-500"
                  /> */}
                </div>
                <div>
                  <label htmlFor="" className="block mb-2 text-white">
                    <span className="text-bold text-lg text-red-600">*</span>{" "}
                    Phone Number
                  </label>
                  <input
                    type="tel"
                    required
                    name="number"
                    value={selectedNumber}
                    onChange={(event) => handleChange(event, 1)}
                    placeholder="eg. (XXX) XXX-XXXX"
                    className="text-black w-full px-4 py-2 border border-gray-300 rounded-3xl focus:outline-none focus:border-purple-500"
                  />
                </div>
              </div>
              <div className="block space-y-4 lg:space-y-0 lg:grid gap-4 lg:grid-cols-2">
                <div>
                  <label htmlFor="" className="block mb-2 text-white">
                    <span className="text-bold text-lg text-red-600">*</span>{" "}
                    College
                  </label>
                  <div className="p-2 border border-white rounded-3xl relative bg-white">
                    <select
                      // name="college"
                      // id="college-input"
                      autoComplete="off"
                      value={selectedCollege}
                      onChange={(event) => handleChange(event, 2)}
                      className="text-black ml-2 block appearance-none w-full bg-transparent border-none focus:outline-none cursor-pointer"
                    >
                    {collegeOptions.map(option => (
                      <option key={option.value} value={option.value}>
                        <div>{option.label}</div>
                      </option>
                    ))}
                    </select>
                  </div>
                </div>
                <div className="text-white">
                  <label htmlFor="" className="block mb-2 text-white">
                    <span className="text-bold text-lg text-red-600">*</span>{" "}
                    College Email
                  </label>
                  <textarea
                    className="w-full px-4 py-2 border border-gray-300 rounded-3xl bg-white text-black resize-none overflow-hidden whitespace-nowrap"
                    readOnly={selectedCollege !== "University of Virginia" && selectedCollege !== "Duke University"}
                    rows={1}
                    value={selectedEmail}
                    placeholder={"eg. name@" + collegeMap[selectedCollege]["domain"]}
                    onChange={(e) => setSelectedEmail(e.target.value)}
                  />
                  {/* <input
                    disabled
                    type="text"
                    required
                    // name="email"
                    value={selectedEmail || ""}
                    onChange={(event) => handleChange(event, 3)}
                    placeholder={`eg. joesmith2024@u.northwestern.edu`}
                    className="text-white w-full px-4 py-2 border border-gray-300 rounded-3xl focus:outline-none focus:border-purple-500"
                  /> */}
                </div>
              </div>
              <div className="block space-y-4 lg:space-y-0 lg:grid gap-4 lg:grid-cols-2">
                <div>
                  <label htmlFor="" className="block mb-2 text-white">
                    <span className="text-bold text-lg text-red-600">*</span>{" "}
                    Pickup Spot
                  </label>
                  <div className="p-2 border border-white rounded-3xl relative bg-white">
                    <select
                      required
                      autoComplete="off"
                      value={selectedPickup || ""}
                      onChange={(event) => handleChange(event, 4)}
                      className={` ml-2 block appearance-none w-full bg-transparent border-none focus:outline-none cursor-pointer ${
                        selectedPickup === null ? "text-gray-400" : "text-black"
                      }`}
                    >
                    {!selectedPickup && (
                      <option disabled value="">
                        Select your preferred pickup spot...
                      </option>
                    )}
                    {collegeMap[selectedCollege]["pickup-spots"].map(option => (
                      <option className="text-black" key={option.name} value={option.name}>
                        <div>{option.name}</div>
                      </option>
                    ))}
                    </select>
                  </div>
                </div>
                <div>
                  <label htmlFor="" className="block mb-2 text-white">
                    <span className="text-bold text-lg text-red-600">*</span>{" "}
                    Airport
                  </label>
                  <div className="p-2 border border-white rounded-3xl relative bg-white">
                    <select
                      required
                      autoComplete="off"
                      value={selectedRoute || ""}
                      onChange={(event) => handleChange(event, 5)}
                      className={`ml-2 block appearance-none w-full bg-transparent border-none focus:outline-none cursor-pointer ${
                        selectedRoute === null ? "text-gray-400" : "text-black"
                      }`}
                    >
                    {!selectedRoute && (
                      <option disabled value="">
                        Select your departure airport...
                      </option>
                    )}
                    {collegeMap[selectedCollege]["location-options"].map(option => (
                      <option className="text-black" key={option} value={option}>
                        <div>{option}</div>
                      </option>
                    ))}
                    </select>
                  </div>
                </div>
              </div>
              <div className="block space-y-4 lg:space-y-0 lg:grid gap-2 lg:grid-cols-3">
                <div>
                  <label htmlFor="" className="block mb-2 text-white">
                    <span className="text-bold text-lg text-red-600">*</span>{" "}
                    Pickup Date
                  </label>
                  <CustomDatePicker
                    date={true}
                    early={null}
                    selected={selectedDate}
                    handleChange={handleChange}
                    tomorrow={selectedDate}
                  />
                </div>
                <div className="w-full">
                  <label htmlFor="" className="block mb-2 text-white">
                    <span className="text-bold text-lg text-red-600">*</span>{" "}
                    Earliest Pickup Time &#40;{collegeMap[selectedCollege]["timezone-short"]}&#41;
                  </label>
                  <CustomDatePicker
                    date={false}
                    early={true}
                    selected={selectedEarliest}
                    handleChange={handleChange}
                    tomorrow={selectedDate}
                    earliest={selectedEarliest}
                  />
                </div>
                <div>
                  <label htmlFor="" className="block mb-2 text-white">
                    <span className="text-bold text-lg text-red-600">*</span>{" "}
                    Latest Pickup Time &#40;{collegeMap[selectedCollege]["timezone-short"]}&#41;
                  </label>
                  <CustomDatePicker
                    date={false}
                    early={false}
                    selected={selectedLatest}
                    handleChange={handleChange}
                    tomorrow={selectedDate}
                    earliest={selectedEarliest}
                  />
                </div>
              </div>
              <p className="p-2 text-white text-xs">
                <span className="text-bold text-lg text-red-600">*</span>{" "}
                indicates a required field
              </p>
              <div className="flex justify-center text-white text-xs">
                <input
                  className="m-3"
                  type="checkbox"
                  required
                  id="consent"
                  name="consent"
                  onChange={(event) => handleChange(event, 9)}
                />
                <label className="w-1/2" htmlFor="">
                  <p><span className="text-bold text-lg text-red-600">*</span>{" "} I consent to receiving SMS from SPLIT. Messaging and data rates may apply. </p>
                </label>
              </div>
              <div className="flex flex-col justify-center items-center gap-y-3">
                <div className={`${googleVerified ? "" : "hidden"} lg:w-[50%] w-[300px]`}>
                  <GoogleOAuthProvider clientId="950716568830-qdufri34l2mi9dg1kbj4r443abaniv63.apps.googleusercontent.com">
                    <VerifyWithGoogleButton setGoogleVerified={setGoogleVerified} googleVerified={googleVerified} changeName={setSelectedName} changeEmail={setSelectedEmail} domain={collegeMap[selectedCollege]["domain"]}/>
                  </GoogleOAuthProvider>
                </div>
                <div className={`${microsoftVerified ? "" : "hidden"} ${selectedCollege === "University of Virginia" || selectedCollege === "Duke University" ? "hidden" : ""} lg:w-[50%] w-[300px]`}>
                    <VerifyWithMicrosoftButton setMicrosoftVerified={setMicrosoftVerified} microsoftVerified={microsoftVerified} changeName={setSelectedName} changeEmail={setSelectedEmail} domain={collegeMap[selectedCollege]["domain"]}/>
                </div>
                {/* <div className="w-[50%]">
                  <VerifyWithEmailButton />
                </div> */}
                <div className="lg:w-[50%] w-[300px]">
                  <button
                    disabled={!googleVerified && !microsoftVerified}
                    type="submit"
                    // onClick={(event) => pushToFirebase(event)}
                    onClick={(e) => {
                      e.preventDefault();
                      const allFieldsFilled = Object.values(formData).every(value => value !== null && value !== undefined && value !== '');
                    
                      if (allFieldsFilled && consent) {
                        setShowConfirmation(true);
                        if (isMobileDevice()) {
                            scrollToTop();
                        } else {
                            scrollToTarget();
                        }
                      } else {
                        alert('You must fill out all fields and provide consent.');
                      }
                    }}
                    className="w-[100%] text-white font-medium p-2 rounded-3xl bg-purple-700"
                  >
                    Submit
                  </button>
                </div>
              </div>
              <div className="text-white align-center items-center justify-center p">
                <i>
                  With multi-stop rides, we now guarantee that you will be picked up at your
                  selected pickup location!
                </i>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
